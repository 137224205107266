<div class="animated fadeIn">
  <div class="row">
    <div class="col-lg-12">
      <div class="card text-black bg-white mb-3">
        <div class="card-body">
          <h5 class="card-title">Memories</h5>
          <div class="text-black display-4 text-end">
            <p>{{memoryCount.activeCount}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="isLoad" class="animated fadeIn">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <span><i class="icon-user-following"></i> Memories</span>
          </div>
          <div class="card-body">
            <div
              class="table-responsive{-sm|-md|-lg|-xl} table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl">
              <table class="table table-striped table-hover">
                <thead class="table-primary">
                  <tr>
                    <th>UserName</th>
                    <th>Email</th>
                    <th>Memories</th>
                    <th>Last Login<th>                    
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="6" class="text-center mt-3">
                      <div class="sk-wandering-cubes">
                        <div class="sk-cube sk-cube1"></div>
                        <div class="sk-cube sk-cube2"></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="animated fadeIn" *ngIf="!isLoad  && isSearch == false  ">
    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-3">
          <div class="card-header">
            <span><i class="fa fa-users"></i> Memories</span>

          </div>
          <div class="card-body">
            <div class="row mb-3">
              <!-- <form class="col-md-5 offset-md-7">
                 <div class="input-group clear">
                  <span *ngIf="searchValue!=''">
                    <i class="fa fa-close" (click)="clear()"></i>
                  </span>
                  <input type="text" name="search" class="form-control" (keyup)="keyUp()" autocomplete="off"
                    value={{searchValue}} placeholder="Search by name, phone, email" (paste)="searchChange()" />
                  <div class="input-group-prepend">
                    <button type="submit" class="input-group-text btn btn-wave btn-light" (click)="searchByValue()">
                      <i class="fa fa-search"></i>
                    </button>
                    
                  </div>
                </div>  </form> -->
              <form class="col-lg-12 col-md-12 col-sm-12 input-group mb-4">
                <input class="form-control" type="text" name="search" (keyup)="keyEvent($event)"
                  (keydown)="keyEvent($event)" placeholder="Search by FirstName, LastName" autocomplete="off"
                  value={{searchQuery}}>
                <div class="input-group-prepend">
                  <button class="input-group-text btn btn-wave btn-light" type="submit"
                    [disabled]="searchQuery==''||searchQuery==undefined" (click)="searchByValue($event)"><i
                      class="fa fa-search"></i>
                  </button>
                </div>
                <div class="input-group-prepend">
                  <button (click)="handleClear()" class="input-group-text btn btn-wave btn-light"
                    [disabled]="searchQuery==''||searchQuery==undefined" (click)="searchByValue($event)"><i
                      class="fa fa-close"></i>
                  </button>
                </div>
              </form>
            </div>
            <div class="row">
              <div class="col-lg-10 col-md-7 col-sm-12">
                <pagination-controls previousLabel="Prev" nextLabel="Next" [responsive]="true"
                  (pageChange)="handlePageChange($event)"></pagination-controls>
              </div>
              <div class="col-lg-2 col-md-5 col-sm-12 pull-right">
                <span class="float-right">
                  Items Per Page
                  <select (change)="handlePageSizeChange($event)">
                    <option *ngFor="let size of pageSizes" [ngValue]="size" [selected]="size == PageSize">
                      {{ size }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
            <div
              class="table-responsive{-sm|-md|-lg|-xl} table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl">
              <table class="table table-striped table-hover">
                <thead class="table-primary">
                  <tr> 
                    <th>UserName</th>                                    
                    <th>Email</th>
                    <th>Memories</th>
                   
                      <th id="tname" (click)="onSort(1)" [class.asc]="direction==='asc'"
                      [class.desc]="direction === 'desc'" style="cursor: pointer;">Last Login<span style="float:right">
                        <div
                          *ngIf="column  && (direction === 'desc' ||  direction === 'asc');then content else other_content">
                        </div>
                        <ng-template #content>
                          <i class="fa fa-sort-down" *ngIf="direction === 'desc'"></i>
                          <i Class="fa fa-sort-up" *ngIf="direction === 'asc'"></i>
                        </ng-template>
                        <ng-template #other_content>
                          <i *ngIf="sort" class="fa fa-sort"></i>
                        </ng-template>
                      </span></th> 
                      <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of list | paginate:{ 
                    itemsPerPage: PageSize,
                    currentPage: PageNumber,
                    totalItems: totalItems
                    
                  }">
                    <td>{{data.firstName}} {{data.lastName}}</td>
                    <td>{{data.email}} </td>
                    <th><a [routerLink]="['/all-memories']" [queryParams]="{ userId: data.userId }">{{data.profileCount}}</a></th> 
                    <th>{{data.updatedDate | date :'dd MMM yyyy, HH:mm\'hrs\'' }}</th>
                    <td>
                   
                  </tr>
                  <tr *ngIf="emptytable">
                    <td class="text-center mt-3" colspan="6">No data found</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-lg-10 col-md-6 col-sm-12">
                <pagination-controls previousLabel="Prev" nextLabel="Next" [responsive]="true"
                  (pageChange)="handlePageChange($event)"></pagination-controls>
              </div>
              <div class="col-lg-2 col-md-6 col-sm-12 pull-right">
                <span class="float-right">
                  Items per Page
                  <select (change)="handlePageSizeChange($event)">
                    <option *ngFor="let size of pageSizes" [ngValue]="size" [selected]="size == PageSize">
                      {{ size }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
            <!-- <div class="pagi-respon">
              <div class="pagination mx-auto">
                <div class="row page">
                  <div class="div-sec">
                    <pagination-controls previousLabel="Prev" nextLabel="Next" [responsive]="true" [maxSize]="5"
                      class="pagination" >
                    </pagination-controls>
                  </div>
                  <div class="col mblesize">
                   
                    <select class="form-control" >
                      
                    
                    </select>
                  </div>
                </div>
              </div>
            </div> -->

          </div>
        </div>
      </div>
    </div>
  </div>



  
</div>

  <!--New mahial-->