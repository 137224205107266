import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';//import { environment } from 'src/environments/environment';
import { SearchencodeService} from '../searchencode/searchencode.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private API_URL = environment.API_URL;

  constructor(private http: HttpClient) { }

  getUserList(){    
     return this.http.get(`${this.API_URL}/ImmortalUser/GetAllUsers`, {     
       headers: new HttpHeaders({
         "Content-Type": "application/json",
         // "Authorization":"Bearer " +localStorage.getItem("token") 
      }),
     })
   }
  //  getAllInActiveUsersList(){
  //   // debugger;
  //    return this.http.get(`${this.API_URL}/ImmortalAdmin/GetAllInActiveUsers`, {     
  //      headers: new HttpHeaders({
  //        "Content-Type": "application/json",
  //        // "Authorization":"Bearer " +localStorage.getItem("token") 
  //     }),
  //    })
  //  }
   getUserListWithPagination(paramsReq:any): Observable<any> {  
    let params = new HttpParams({ encoder: new SearchencodeService() })
       .set('PageNumber', paramsReq.PageNumber)
       .set('PageSize', paramsReq.PageSize)       
       .set('SortBy', paramsReq.SortBy)
       .set('sortColumn', paramsReq.sortColumn);
       if (paramsReq.searchTerm) {
        params = params.append('searchTerm', paramsReq.searchTerm);
      }
     return this.http.get(`${this.API_URL}/ImmortalAdmin/GetUserListWithPagination`, {     
         headers: new HttpHeaders({
           "Content-Type": "application/json",
         //  Authorization: "Bearer " + localStorage.getItem("token"),
         }),
         params: params,
       })
   }
   getInactiveUserListWithPagination(paramsReq:any): Observable<any> {    
     let params = new HttpParams({ encoder: new SearchencodeService() })
        .set('PageNumber', paramsReq.PageNumber)
        .set('PageSize', paramsReq.PageSize)       
        .set('SortBy', paramsReq.SortBy)
        .set('sortColumn', paramsReq.sortColumn);
        if (paramsReq.searchTerm) {
         params = params.append('searchTerm', paramsReq.searchTerm);
       }
      return this.http.get(`${this.API_URL}/ImmortalAdmin/GetInActiveUserListWithPagination`, {     
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          //  Authorization: "Bearer " + localStorage.getItem("token"),
          }),
          params: params,
        })
    }
   getUserbyId(userId: any): Observable<any> {   
     return this.http.get(`${this.API_URL}/ImmortalAdmin/GetUserById?userId=` + userId, {
       headers: new HttpHeaders({
         "Content-Type": "application/json",
        // Authorization: "Bearer " + localStorage.getItem("token"),
       })
     })
   }

   updateUserStatus(userId:string | number ,userData:any): Observable<any> {     
     return this.http.put(`${this.API_URL}/ImmortalAdmin/UpdateUserStatus?userId=` + userId , userData,{
       headers: new HttpHeaders({
         "Content-Type": "application/json",
       //  "Authorization": "Bearer " + localStorage.getItem("token")
       }),responseType: 'text'
     })
   }
   getActiveUserCount(){   
     return this.http.get(`${this.API_URL}/ImmortalAdmin/GetAllActiveUsersCount`, {     
       headers: new HttpHeaders({
         "Content-Type": "application/json",
         // "Authorization":"Bearer " +localStorage.getItem("token") 
      }),
     })
   }
   getInActiveUserCount(){   
     return this.http.get(`${this.API_URL}/ImmortalAdmin/GetAllInActiveUsersCount`, {     
       headers: new HttpHeaders({
         "Content-Type": "application/json",
         // "Authorization":"Bearer " +localStorage.getItem("token") 
      }),
     })
   }  
}
