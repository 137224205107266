import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MemoriesService } from "../../../services/memories/memories.service";
import {configuration} from '../../../../app/configuration/configuration';
@Component({
  selector: 'app-memories',
  templateUrl: './memories.component.html',
  styleUrls: ['./memories.component.scss']
})
export class MemoriesComponent implements OnInit {
  isLoad = true;
memories : any[] =[];
id:any;

userId: any;
firstName: string | undefined;
memoryCount: any;

//pagination stats
list: any;
public PageSize = configuration.PAGESIZE;
public PageNumber = configuration.PAGENUMBER;
public pageSizes = configuration.PAGESIZES;
totalItems: any;
totalPages: any;
searchQuery = '';
isSearch = false;
emptytable = false;
direction = 'desc';
sort = true; 
column = '1';
selectedPageSize = this.pageSizes[0];
// pagination ends
memories1 =[{
  "user":{
            "firstName": "Adam",
            "lastName": "Moreira",
  },
  "profile":{
    "profileType": "Person",
    "firstName": "RAja RAm",
    "lastName": "Mohan Roy",
  }
  
}]
  

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,

    private httpClient: HttpClient,
    private activatedRouter: ActivatedRoute,
    private router: Router,
    public vcRef: ViewContainerRef,
    //private toastr: ToastrService,
    // private navigationItems: NavigationItems,
    // private toastr: ToastrService,
    private memoriesService: MemoriesService
  ) { }

  ngOnInit(): void {
   // this.userId = + this.activatedRouter.snapshot.paramMap.get('userId'); 
   // this.getMemoryList();
   this.activatedRouter.queryParamMap.subscribe((paramsVal) => {
    let tempPageNumber = paramsVal.get("PageNumber");
    let tempPageSize = paramsVal.get("PageSize");
    // let tempFilter = paramsVal.get("filterId");
    let tempSearch = paramsVal.get("SearchTerm");
    if (tempPageNumber !== undefined && tempPageNumber !== null) {
      this.PageNumber = Number(tempPageNumber);
    }
    if (tempPageSize !== undefined && tempPageSize !== null) {
      if (this.pageSizes.indexOf(Number(tempPageSize)) > -1) {
        this.PageSize = Number(tempPageSize);
      }
    }
    if (tempSearch !== undefined && tempSearch !== null) {
      this.searchQuery = tempSearch.toString();
    }
  })
  this.objmethod(); 
    this.GetAllProfilesCount();
  }
  // getMemoryList() {   
  //  // debugger;
  //   this.isLoad = false;
  //   this.memoriesService.getMemoriesList().subscribe((res: any) => {  
  //     //this.memories = res.memories || res;     
  //     this.memories = res;     
     
  //   })   
  // }
  hideloader() {
    throw new Error('Method not implemented.');
  }
  showloader() {
    throw new Error('Method not implemented.');
  }
  GetAllProfilesCount(){
    this.memoriesService.GetAllProfilesCount().subscribe((res: any) => {    
      this.memoryCount = res;  
      console.log('memoriesCount:', res.activeCount)
  })
}


//pagination starts
fetchData(obj: any) {
  this.isLoad = true;
  this.memoriesService.getMemoriesListWithPagination(obj).subscribe((res: any) => {
    this.isLoad = false;
    this.list = res.list;
    this.PageSize = res.pageSize;
    this.PageNumber = res.pageNumber;
    this.totalItems = res.totalItems;
    this.totalPages = res.totalPages;
    this.searchQuery = res.searchTerm;
    this.isSearch = false;
    if (this.list.length == 0) {
      this.emptytable = true;
    } else {
      this.emptytable == false;
    }
  })
}
keyEvent(event: any) {
  this.searchQuery = (event.target.value).trim();
}
searchByValue(event: any) {
  this.isSearch = true;
  this.PageNumber = 1;
  this.router.navigate(["/memories"], {
    queryParams: {
      pageNumber: this.PageNumber,
      pageSize: this.PageSize,
      // filterId: this.filterId,
      SortBy: this.direction,
      searchTerm: this.searchQuery.trim(),
    },
  });
  this.objmethod();
}
objmethod() {
  let obj = {      
    PageNumber: this.PageNumber.toString(),
    PageSize: this.PageSize.toString(),
    SortBy: 'desc',
    sortColumn: '1',
    searchTerm: this.searchQuery.trim()
  }
  if (this.searchQuery) {
    obj['searchTerm'] = this.searchQuery.trim()
  }
  this.fetchData(obj);
}
handleClear() {
  this.searchQuery = "";
  this.emptytable = false;
  // this.objmethod();
}
handlePageChange(event: any) { 
  this.PageNumber = event;
  this.router.navigate(["//memories"], {
    queryParams: {
      pageNumber: this.PageNumber,
      pageSize: this.PageSize,
      //filterId: this.filterId,
      SortBy: this.direction,
      SearchTerm: this.searchQuery.trim(),
    },
  });
  this.objmethod();
}
handlePageSizeChange(event: any) {
  this.PageSize = event.target.value;
  this.PageNumber = 1;
  this.router.navigate(["/memories"], {
    queryParams: {
      pageNumber: this.PageNumber,
      pageSize: this.PageSize,
      //filterId: this.filterId,
      SortBy: this.direction,
      SearchTerm: this.searchQuery.trim(),
    },
  });

  this.objmethod();
}
onSort(column: any) {
  //this.direction = this.rotate[this.direction];
  const rotate: { [key: string]: string } = { "": "asc", "asc": "desc", "desc": "" };

  this.direction = rotate[this.direction];
  // this.direction = this.rotate.desc;
  //this.direction = this.rotate2[this.direction];
  // debugger;
  this.column = this.direction == "" ? "4" : column;
  let obj = {
    PageNumber: this.PageNumber.toString(),
    PageSize: this.PageSize.toString(),
    //SortBy: 'desc',
    SortBy: this.direction,
    sortColumn: '4',
    searchTerm: this.searchQuery.trim()
  }
  if (this.searchQuery) {
    obj['searchTerm'] = this.searchQuery ? this.searchQuery.trim() : ""
  }
  // if (this.searchQuery) {
  //   obj['SearchTerm'] = this.searchQuery.trim()
  // }

  this.fetchData(obj);
  console.log('Fetch:', obj)
}
//pagination ends
}
